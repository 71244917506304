export function replaceAsterisks(
  baseString: string,
  replacements: Array<string>,
) {
  const replacedString = baseString.replace(
    /\*\*(?![^{]*})/g,
    function (match) {
      return replacements.shift() || match;
    },
  );
  return replacedString;
}
